<template>
	<v-card v-if="maps && maps.length" flat height="100%" class="wr_grey_1">
		<!-- Create button -->
		<v-btn
			v-if="!isFinalStep"
			fab
			large
			color="white"
			class="elevation-12"
			:style="createBtnStyle"
			@click.stop="createMap()"
		>
			<v-icon large>$vuetify.icons.values.plus</v-icon>
		</v-btn>

		<!-- Only for step 1 -->
		<v-row no-gutters v-if="!isFinalStep">
			<v-col
				v-for="map in maps"
				:key="map.id"
				:class="['px-2', $vuetify.rtl ? 'transform-maps' : '']"
				:id="map.id"
			>
				<v-row no-gutters>
					<v-col sm="11" style="height:700px" class="mt-5">
						<StandardMindmap
							:prop_map_id="map.id"
							:prop_style="{
								border: map.selected ? '3px solid black !important' : '',
							}"
							@on-map-saved="updateMap($event, map.id)"
						></StandardMindmap>
					</v-col>

					<!-- Actions Icons -->
					<v-col class="pt-7">
						<!-- Select Map -->
						<v-icon
							v-if="maps.length > 1"
							color="black"
							v-tooltip="getTooltipConfig('app.select_map')"
							@click="updateSelectedMap(map.id)"
						>
							{{
								$vuetify.icons.values[
									map.selected ? "check_circle" : "circle_outline"
								]
							}}</v-icon
						>

						<!-- Delete Map -->
						<v-img
							v-if="
								currentStageMaps.length > 1 &&
									map.name != $defines.BRANCO_WEISS_MAP_NAME
							"
							:src="require('@/assets/images/bin.png')"
							width="25"
							class="cursor-wr mt-5"
							v-tooltip="getTooltipConfig('app.delete_map')"
							@click="deleteMap(map.id)"
						>
						</v-img>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<v-card v-else height="100%" flat>
			<StandardMindmap
				:prop_map_id="selectedMap.id"
				:show_fav_icon="true"
				@on-map-saved="updateMap($event, selectedMap.id)"
			></StandardMindmap>
		</v-card>
	</v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import {
	findElIndex,
	findFromArray,
	filterArray,
	flatArrayOfObjects,
	generateRandomString,
} from "@/utils/helpers";
import { questionnaireHelper } from "@/utils/helpers/questionnaire";
import StandardMindmap from "@/components/shared/tools/standardMindmap/Index.vue";

export default {
	name: "ChooseTopicAltStandardMindmaps",

	data() {
		return {
			maps: [],
		};
	},

	components: {
		StandardMindmap,
	},

	created() {
		this.readyToListenEvents();
	},

	mounted() {
		this.init();
	},

	computed: {
		...mapGetters({
			isCurrentStepCompleted: "process/isCurrentStepCompleted",
		}),

		...mapState({
			current_step: (state) => state.process.current_step,
			rq_general: (state) => state.process.rq_general,
			current_module: (state) => state.research.current_module,
			current_stage: (state) => state.research.current_stage,
		}),

		createBtnStyle() {
			return {
				position: "fixed",
				bottom: "12%",
				zIndex: "1",
				[this.$vuetify.rtl ? "left" : "right"]: "2%",
			};
		},

		isFinalStep() {
			return this.current_step && this.current_step.name == "selectFavNodes";
		},

		totalMapsLength() {
			return this.maps.length;
		},

		currentStageMaps() {
			return filterArray(
				this.maps,
				"name",
				this.$defines.BRANCO_WEISS_MAP_NAME,
				false,
			);
		},

		selectedMap() {
			return findFromArray(this.maps, "selected", true);
		},
	},

	watch: {
		totalMapsLength: {
			handler: function(newVal) {
				this.reOrderMaps();
			},
		},
	},

	methods: {
		async init() {
			try {
				this.$loader.start();
				// Get all maps of user
				await this.getMapsFromApi();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		reOrderMaps() {
			// find the "branco_weiss_er4ftv" named map
			let branco_map_index = findElIndex(
				this.maps,
				"name",
				this.$defines.BRANCO_WEISS_MAP_NAME,
			);
			// if branco map found
			if (branco_map_index !== -1) {
				let branco_map = this.maps[branco_map_index];
				// first remove the branco map
				this.maps.splice(branco_map_index, 1);
				// then push at the last
				this.maps.push(branco_map);
			}
		},

		async getMapsFromApi() {
			try {
				// Get the mind maps list from the API
				let response = await this.$store.dispatch("mindmap/list", {
					category: this.$defines.SYSTEM_CATEGORY,
				});

				// If some response present then check
				if (response && response.data && response.data.length) {
					// If map other than "branco_weiss_er4ftv" name present, use them
					this.maps = response.data;

					if (this.currentStageMaps.length) {
						this.maps = response.data;
						return this.reOrderMaps();
					}
				}

				await this.createMap();
			} catch (error) {
				throw error;
			}
		},

		getTooltipConfig(text) {
			return {
				content: this.$t(text),
				placement: this.$vuetify.rtl ? "left" : "right",
				targetClasses: ["it-has-a-tooltip"],
				offset: 10,
				classes: ["edit"],
			};
		},

		readyToListenEvents() {
			this.$eventBus.$on("smm-validate-initial-step", (payload) => {
				this.validateInitialStep();
			});
			this.$eventBus.$on("smm-validate-final-step", (payload) => {
				this.validateFinalStep();
			});
		},

		async updateSelectedMap(map_id) {
			try {
				this.$loader.start();

				// Loop on all maps and update their status
				for (const map of this.maps) {
					map.selected = map.id == map_id;

					await this.$store.dispatch("standardMindmap/save", {
						id: map.id,
						data: map.data,
						selected: map.id == map_id,
					});
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async createMap() {
			try {
				this.$loader.start();

				let root_node_title = this.$t("app.root_node");

				// Choosen theme in previous stage would be the root node title
				// if "import_theme_to_nodes" is set to true
				if (this.rq_general.import_theme_to_nodes) {
					let result = await questionnaireHelper.getChosenTheme();
					if (result && result.answer) {
						root_node_title = result.answer;
					}
				}

				let response = await this.$store.dispatch("mindmap/create", {
					data: {
						linkData: {},
						nodeData: {
							id: generateRandomString(),
							root: true,
							topic: root_node_title,
						},
					},
				});

				// Push this newly created map into local state
				await this.maps.push(response);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		updateMap(payload, map_id) {
			// Find the selected map
			let objIndex = findElIndex(this.maps, "id", Number(map_id));

			// If index found then update the data at index.
			if (objIndex !== -1) {
				this.$set(this.maps[objIndex], "data", payload.data);
			}
		},

		async deleteMap(map_id) {
			try {
				this.$loader.start();

				let response = await this.$store.dispatch("mindmap/delete", {
					id: map_id,
				});

				let index = findElIndex(this.maps, "id", map_id);
				if (index != -1) {
					this.$delete(this.maps, index);
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async validateInitialStep() {
			try {
				// If no maps created
				if (!this.maps.length) {
					throw new Error("app.ntfy.err.create_map_first", {
						cause: "werCustom",
					});
				}

				// If only single map is created, mark it as selected
				if (this.maps.length === 1) {
					await this.updateSelectedMap(this.maps[0].id);
				}

				// If more than one map is created then select at least one.
				if (this.maps.length > 1 && !this.selectedMap) {
					throw new Error("app.ntfy.err.select_map_first", {
						cause: "werCustom",
					});
				}

				// Validate notes counting before moving next
				this.validateNodesCounting();

				this.$eventBus.$emit("smm-redirect-next");
			} catch (error) {
				this.$announce.error(error);
			}
		},

		validateFinalStep() {
			try {
				// Validate notes status before finish work
				this.validateNodesCounting();

				// Validate the notes which added to favorites.
				this.validateNodesFavStatus();

				// Finish the work
				this.$eventBus.$emit("smm-finish-work");
			} catch (error) {
				this.$announce.error(error);
			}
		},

		validateNodesCounting() {
			let nodes = this.getSelectedMapNodes();

			if (nodes.length < 6) {
				throw new Error(
					this.$t("app.ntfy.err.min_nodes_except_title_node", {
						total: 6,
					}),
					{
						cause: "werCustom",
					},
				);
			}
		},

		validateNodesFavStatus() {
			let nodes = this.getSelectedMapNodes();

			let fav_nodes = filterArray(nodes, "fav", true, true);
			if (fav_nodes.length < 3) {
				throw new Error("app.ntfy.err.min_fav_items", {
					cause: "werCustom",
				});
			}
		},

		getSelectedMapNodes() {
			// Find the selected map

			let result = [];

			// push root node first
			result.push(this.selectedMap.data.nodeData);

			// Flat all child nodes
			if (this.selectedMap.data.nodeData.children) {
				result = [
					...result,
					...flatArrayOfObjects(
						this.selectedMap.data.nodeData.children,
						"children",
					),
				];
			}

			return result;
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */

		this.$eventBus.$off("smm-validate-initial-step");
		this.$eventBus.$off("smm-validate-final-step");
	},
};
</script>

<style scoped>
>>> .map-container {
	direction: ltr !important;
}
</style>
